import * as React from 'react'
import PropTypes from 'prop-types'
import { motion } from 'framer-motion'

// For most use-cases this component will need to be wrapped by `AnimatePresence`
export function GameTileAnimation(props) {
  return (
    <motion.div
      initial={{ scale: 0.8, opacity: 0 }}
      animate={{ scale: 1, opacity: 1 }}
      exit={{
        scale: 0.8,
        opacity: 0,
        transition: { duration: 0 },
      }}
      transition={{
        duration: 0.25,
        delay: props.delay,
      }}
      style={{ display: 'inline-grid' }}
    >
      {props.children}
    </motion.div>
  )
}

GameTileAnimation.defaultProps = {
  delay: 0,
}

GameTileAnimation.propTypes = {
  children: PropTypes.node.isRequired,
  delay: PropTypes.number,
}
