import * as React from 'react'
import PropTypes from 'prop-types'

import * as t from '@rushplay/theme'
import styled from '@emotion/styled'

import { GAME_TILE_MULTIPLIER, GameTileHeight } from './constants'

// Extra spacing is needed for Picked games page, since
// there is a overlapping Round button on the card.
// TODO: Make seperate game section component instead.
function tileHeight(size, multipler = 1, extraSpacing = 0) {
  switch (size) {
    case 'large': {
      return `${GameTileHeight.LARGE * multipler + extraSpacing}px`
    }
    case 'medium': {
      return `${GameTileHeight.MEDIUM * multipler + extraSpacing}px`
    }
    default: {
      return `${GameTileHeight.SMALL * multipler + extraSpacing}px`
    }
  }
}

const Base = styled.div`
  height: ${props =>
    props.expand
      ? 'auto'
      : tileHeight(
          props.size === 'small' ? 'medium' : props.size,
          GAME_TILE_MULTIPLIER,
          props.extraSpacing
        )};

  ${t.mediaQuery.sm`
  ${props =>
    `height: ${
      props.expand ? 'auto' : tileHeight(props.size, 1, props.extraSpacing)
    }`};
`};
`

export const GameTileExpandableRow = React.forwardRef((props, ref) => {
  return (
    <Base
      ref={ref}
      size={props.size}
      extraSpacing={props.extraSpacing}
      expand={props.expand}
    >
      {props.children}
    </Base>
  )
})

GameTileExpandableRow.displayName = 'GameTileExpandableRow'

GameTileExpandableRow.propTypes = {
  children: PropTypes.node,
  columns: PropTypes.number,
  expand: PropTypes.bool,
  extraSpacing: PropTypes.number,
  size: PropTypes.string,
}
