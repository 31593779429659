import * as React from 'react'

import * as Herz from '@rushplay/herz'
import * as Common from '@rushplay/common'

import * as Constants from './constants'
import { Button } from './button'
import { HtmlContent } from './html-content'

export function SeoSection() {
  const translate = Herz.I18n.useTranslate()
  const [isExtended, setIsExtended] = React.useState(false)

  return (
    <Common.Box
      maxWidth={Constants.ContentMaxWidth}
      display="flex"
      flexDirection="column"
    >
      <Common.Box
        maxHeight={isExtended ? '100%' : '230px'}
        overflow={isExtended ? null : 'hidden'}
        color="text"
      >
        <HtmlContent
          html={{
            __html: translate('landing-page.seo-content'),
          }}
        />
      </Common.Box>
      <Common.Box width="100%" display="flex" justifyContent="center">
        <Button
          variant="secondary"
          data-testid="seo-extend"
          onClick={() => setIsExtended(prevState => !prevState)}
        >
          <Common.Box color="#074033" fontSize="14px" fontWeight="600">
            {isExtended
              ? translate('icons.show-less')
              : translate('icons.show-more')}
          </Common.Box>
        </Button>
      </Common.Box>
    </Common.Box>
  )
}

Herz.I18n.Loader.preload(
  ['icons.show-more', 'icons.show-less', 'landing-page.seo-content'],
  SeoSection
)
