import * as R from 'ramda'
import * as React from 'react'
import PropTypes from 'prop-types'

import * as Common from '@rushplay/common'
import * as Herz from '@rushplay/herz'
import css from '@styled-system/css'
import styled from '@emotion/styled'

import * as Icons from './icons'
import * as Constants from './constants'
import { GameTileExpandableRow } from './game-tile-expandable-row'
import { GameTileGrid } from './game-tile-grid'

const ShowMore = styled.div`
  ${css({
    fontSize: [2, null, 3],
    fontFamily: 'head',
    fontWeight: 'bold',
    color: 'g-text',
    opacity: 0.5,
    '&:hover': {
      opacity: 1,
    },
  })};
  transition: opacity 75ms ease-in;
  cursor: pointer;
  white-space: nowrap;
`

export function GameTileSection(props) {
  const ref = React.useRef()
  const translate = Herz.I18n.useTranslate()
  const [items, updateItems] = React.useState([])
  const [shouldExpand, setShouldExpand] = React.useState(false)
  const [visibleGames, setVisibleGames] = React.useState(props.columns)

  function handleUpdateItems(item) {
    if (R.not(R.includes(item, items))) {
      updateItems(R.append(item, items))
    } else {
      updateItems(R.reject(R.equals(item), items))
    }
  }

  function handleExpand() {
    const isSmall = props.size === 'small'
    const outerPadding = Constants.isDesktop ? 48 : 32
    const tilePadding = 16
    const gameTileWidth = Constants.GameTileWidth[R.toUpper(props.size)]
    const calculatedWidth =
      !Constants.isDesktop && !isSmall
        ? gameTileWidth * Constants.GAME_TILE_MULTIPLIER
        : gameTileWidth
    const rowLength =
      ref.current &&
      Math.floor(
        (ref.current.getBoundingClientRect().width -
          outerPadding +
          tilePadding) /
          (calculatedWidth + tilePadding)
      )
    const minValue = {
      small: Constants.isDesktop ? R.max(rowLength, 1) : 2,
      medium: Constants.isDesktop ? R.max(rowLength, 1) : 2,
      large: Constants.isDesktop ? R.max(rowLength, 1) : 1,
    }

    setVisibleGames(minValue[props.size])
    setShouldExpand(props.columns > minValue[props.size] && props.columns !== 1)
  }

  React.useEffect(() => {
    handleExpand()
  }, ref)

  React.useEffect(() => {
    window.addEventListener('resize', handleExpand)

    return () => window.removeEventListener('resize', handleExpand)
  }, [])

  if (props.layout === 'grid') {
    return (
      <React.Fragment>
        {props.sectionTitle && (
          <Common.Box
            fontSize={5}
            fontFamily="head"
            fontWeight="bold"
            px={1}
            pb={0}
            color="g-text"
          >
            {props.sectionTitle}
          </Common.Box>
        )}
        <Common.Space px={1} py={0}>
          <GameTileGrid
            columns={props.columns}
            size={props.size}
            containWidth={props.containWidth}
          >
            {props.children({})}
          </GameTileGrid>
        </Common.Space>
      </React.Fragment>
    )
  }

  // Hybrid; row that can expand into grid
  if (props.layout === 'row') {
    return (
      <React.Fragment>
        <Common.Box
          alignItems="center"
          display="flex"
          justifyContent={props.sectionTitle ? 'space-between' : 'flex-end'}
          px={[1, 2]}
          py={0}
        >
          {props.sectionTitle && (
            <Common.Box
              fontSize={5}
              fontFamily="head"
              fontWeight="bold"
              color="g-text"
            >
              {props.sectionTitle}
            </Common.Box>
          )}
          {props.layout === 'row' && shouldExpand && (
            <ShowMore onClick={() => handleUpdateItems(props.id)}>
              {R.includes(props.id, items) ? (
                <React.Fragment>
                  {translate('game-tile-section.show-less')}
                  <Icons.ExpandLess />
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {translate('game-tile-section.show-more')}
                  <Icons.ExpandMore />
                </React.Fragment>
              )}
            </ShowMore>
          )}
        </Common.Box>

        <GameTileExpandableRow
          ref={ref}
          columns={props.columns}
          expand={R.includes(props.id, items)}
          extraSpacing={props.extraSpacing}
          size={props.size}
        >
          <Common.Space px={[1, 2]}>
            <GameTileGrid
              columns={props.columns}
              size={props.size}
              containWidth={props.containWidth}
            >
              {props.children({
                visibleGames: R.includes(props.id, items)
                  ? props.columns
                  : visibleGames,
              })}
            </GameTileGrid>
          </Common.Space>
        </GameTileExpandableRow>
      </React.Fragment>
    )
  }

  return props.children({})
}

Herz.I18n.Loader.preload(
  ['game-tile-section.show-less', 'game-tile-section.show-more'],
  GameTileSection
)

GameTileSection.propTypes = {
  id: PropTypes.string,
  children: PropTypes.func,
  columns: PropTypes.number,
  layout: PropTypes.oneOf(['grid', 'row']),
  containWidth: PropTypes.bool,
  sectionTitle: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  extraSpacing: PropTypes.number,
}
