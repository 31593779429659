import * as React from 'react'

import * as common from '@rushplay/common'

import * as icons from './icons'

// TODO: create a nice loader
export function Spinner() {
  return (
    <common.Box fontSize={8} display="flex" justifyContent="center">
      <icons.Spinner />
    </common.Box>
  )
}
