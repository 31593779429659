import * as R from 'ramda'
import * as DateFns from 'date-fns'
import * as ReactRedux from 'react-redux'
import * as ReactRouter from 'react-router-dom'

import * as Api from '@rushplay/api-client'
import * as Notifications from '@rushplay/notifications'

import * as Analytics from '../analytics'
import * as Promotions from '../promotions'
import { getSafeUpdateQuery } from '../use-safe-update-query'

import { PromotionCard as Component } from './promotion-card'

export const PromotionCard = R.compose(
  ReactRouter.withRouter,
  ReactRedux.connect(
    (state, props) => {
      const activeTo = DateFns.parseISO(props.activeTo)
      const activeFrom = DateFns.parseISO(props.activeFrom)

      const timeData = [
        {
          id: 'day',
          end: DateFns.differenceInDays(activeTo, props.dateNow),
          start: DateFns.differenceInDays(activeFrom, props.dateNow),
        },
        {
          id: 'hour',
          end: DateFns.differenceInHours(activeTo, props.dateNow),
          start: DateFns.differenceInHours(activeFrom, props.dateNow),
        },
        {
          id: 'minute',
          end: DateFns.differenceInMinutes(activeTo, props.dateNow),
          start: DateFns.differenceInMinutes(activeFrom, props.dateNow),
        },
      ]

      return {
        timeLeft: props.upcoming
          ? R.find(R.pathSatisfies(R.lt(0), ['start']), timeData)
          : R.find(R.pathSatisfies(R.lt(0), ['end']), timeData),
      }
    },
    (dispatch, props) => {
      const walletQuery = getSafeUpdateQuery(props.location, {
        wallet: 'deposit',
        promotions: null,
        rewards: null,
      })

      const redirectUrl =
        R.pathEq(['rewardTrigger'], 'deposit', props.campaign) &&
        !R.isEmpty(R.pathOr([], ['rewards'], props.campaign))
          ? `?${walletQuery}`
          : props.ctaLink

      return {
        onClaim: () => {
          if (R.pathEq(['optInState'], 'out', props.campaign)) {
            dispatch(
              Api.optInCampaign(R.path(['id'], props.campaign), {
                success: () => {
                  props.history.push(redirectUrl)
                  return [
                    Promotions.optIn(props.id),
                    Analytics.browsePromotion({
                      title: props.name,
                      destination: redirectUrl,
                    }),
                  ]
                },
                failure: () =>
                  Notifications.add({
                    message: 'errors.promotion-claim.failed',
                    level: 'error',
                  }),
                version: 2,
              })
            )
          } else {
            dispatch(
              Analytics.browsePromotion({
                title: props.name,
                destination: redirectUrl,
              })
            )
            props.history.push(redirectUrl)
          }
        },
      }
    }
  )
)(Component)
