import * as React from 'react'
import * as ReactRedux from 'react-redux'

import * as Herz from '@rushplay/herz'
import * as Common from '@rushplay/common'
import * as Triggers from '@rushplay/triggers'

import * as Player from './player'
import * as Configuration from './configuration'
import { GameLauncher } from './game-launcher'
import { Spinner } from './spinner'
import { useServerConfiguration } from './server-configuration'

function useHorseRacingUrl() {
  const { locale } = useServerConfiguration()
  const { token } = Herz.Auth.useSession()

  const host = ReactRedux.useSelector(state =>
    Configuration.getGameServerUrl(state.configuration)
  )
  const clientType = ReactRedux.useSelector(state =>
    Configuration.getClientType(state.configuration)
  )
  const origin = typeof window !== 'undefined' ? window?.location?.origin : null

  if (!origin || token === undefined) {
    return null
  }

  const url = new URL('/pages/hub88_horseracing/launch-url', host)
  url.searchParams.set('client_type', clientType)
  url.searchParams.set('language', locale?.code)
  url.searchParams.set('token', token === null ? '' : token)

  return url
}

export function HorseRacing() {
  const horseReacingUrl = useHorseRacingUrl()

  const dispatch = ReactRedux.useDispatch()
  const hasPlayerSeenHorseRacing = ReactRedux.useSelector(state =>
    Player.hasPlayerSeenHorseRacing(state.player)
  )
  const getHorseRacingTrigger = Triggers.createGetTriggerSelector()
  const horseRacingTrigger = ReactRedux.useSelector(state =>
    getHorseRacingTrigger(state.triggers, {
      kind: 'horse_racing',
      event: 'onboarding',
    })
  )

  React.useEffect(() => {
    if (!hasPlayerSeenHorseRacing) {
      dispatch([
        Player.updateHorseRacingSeen(true),
        horseRacingTrigger
          ? Triggers.removeSelected(horseRacingTrigger.id)
          : null,
      ])
    }
  }, [dispatch, horseRacingTrigger])

  if (!horseReacingUrl) {
    return (
      <Common.Box width="100%">
        <Spinner />
      </Common.Box>
    )
  }

  return <GameLauncher gameUrl={horseReacingUrl?.href} />
}
