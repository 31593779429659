import * as R from 'ramda'
import * as ReactRouter from 'react-router-dom'
import * as React from 'react'
import PropTypes from 'prop-types'

import * as Common from '@rushplay/common'
import * as Herz from '@rushplay/herz'
import css from '@styled-system/css'
import styled from '@emotion/styled'

import * as Promotions from '../promotions'
import * as Theming from '../theming'
import * as Icons from '../icons'
import { Button } from '../button'
import { HtmlContent } from '../html-content'

export const NewLabel = styled.div`
  display: flex;
  align-items: center;
  min-width: 32px;
  width: fit-content;
  height: 14px;
  font-size: 10px;
  border-radius: 100px;
  background: red;
  cursor: default;
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;

  ${css({
    color: 'static-white',
    backgroundColor: 'danger',
    px: 0,
  })}
`

export function PromotionCard(props) {
  const translate = Herz.I18n.useTranslate(
    () => [
      'promotions.claimed',
      `promotions.${props.id}.button-link`,
      'promotions-label-active',
      'promotions-label-claimed',
      `promotions.${props.id}.img-src`,
      `promotions.time-left.${props.timeLeft.id}.end`,
      `promotions.time-left.${props.timeLeft.id}.start`,
      `promotions.${props.id}.title`,
      `promotions.${props.id}.description`,
      `promotions.${props.id}.terms-conditions`,
      'promotions.terms-and-conditions',
      'promotions.cancel',
      'inventory.unseen-label',
    ],
    [props.id, props.timeLeft.id]
  )

  const rewards = props.campaign.rewards ?? []

  return (
    <Theming.Alternative>
      <Common.Box
        backgroundColor="page-nav-blue"
        color="white"
        borderRadius="12px"
        display="flex"
        alignItems="flex-start"
        justifyContent="center"
        flexDirection="column"
        p={1}
      >
        <Common.Box
          flexShrink="0"
          display="flex"
          flexDirection="row"
          justifyContent="center"
          pb={1}
        >
          <Common.Box
            borderRadius={4}
            backgroundImage={`url(${translate(
              `promotions.${props.id}.img-src`
            )})`}
            backgroundSize="cover"
            backgroundPosition="center"
            backgroundRepeat="no-repeat"
            backgroundColor="disabled"
            height="60px"
            width="60px"
            flexShrink="0"
            style={{ filter: props.upcoming ? 'grayscale(1)' : 'grayscale(0)' }}
          />
          <Common.Box
            pl={1}
            display="flex"
            flexDirection="column"
            justifyContent="center"
          >
            {!props.seen && (
              <Common.Box pb={0}>
                <NewLabel>{translate(`inventory.unseen-label`)}</NewLabel>
              </Common.Box>
            )}
            <Common.Box
              pb="4px"
              fontFamily="head"
              fontSize={2}
              fontWeight="500"
              style={{ whiteSpace: 'pre-wrap' }}
            >
              {translate(
                `promotions.${props.id}.title`,
                R.mergeRight(
                  Promotions.getPromotionMinMax(rewards),
                  Promotions.getPromotionTranslationVariables(rewards)
                )
              )}
            </Common.Box>
            {props.activeTo && !props.upcoming && (
              <Common.Box fontSize={1} pt="4px" color="white" display="flex">
                <Common.Box display="flex" alignItems="center" pr={0}>
                  <Icons.ExpirationTimeIcon />
                </Common.Box>
                {translate(`promotions.time-left.${props.timeLeft.id}.end`, {
                  timeLeft: props.timeLeft.end,
                })}
              </Common.Box>
            )}
            {props.upcoming && (
              <Common.Box fontSize={1} pt={0} color="white" textAlign="center">
                {translate(`promotions.time-left.${props.timeLeft.id}.start`, {
                  timeLeft: props.timeLeft.start,
                })}
              </Common.Box>
            )}
          </Common.Box>
        </Common.Box>

        <Common.Text fontSize={1}>
          <HtmlContent
            html={{
              __html: translate(
                `promotions.${props.id}.description`,
                Promotions.getPromotionTranslationVariables(rewards)
              ),
            }}
          />
        </Common.Text>
        {(translate(`promotions.${props.id}.terms-conditions`) || '').length ? (
          <Common.Box
            fontSize={1}
            color="static-white"
            pt={0}
            style={{ textDecoration: 'underline' }}
          >
            <ReactRouter.Link to={`/promotions/${props.id}`}>
              {translate('promotions.terms-and-conditions')}
            </ReactRouter.Link>
          </Common.Box>
        ) : null}
        {props.ctaLink && !props.upcoming && (
          <Common.Box width="100%" pt={1}>
            <Button
              stretch
              disabled={props.campaign.claimed}
              variant="primary"
              fontSize={1}
              onClick={props.onClaim}
            >
              {props.campaign.claimed
                ? translate('promotions.claimed')
                : translate(`promotions.${props.id}.button-link`)}
            </Button>
          </Common.Box>
        )}
      </Common.Box>
    </Theming.Alternative>
  )
}

PromotionCard.defaultProps = {
  timeLeft: {},
  campaign: {},
}

PromotionCard.propTypes = {
  activeTo: PropTypes.string,
  activeFrom: PropTypes.string,
  campaign: PropTypes.object,
  ctaLink: PropTypes.string,
  timeLeft: PropTypes.object,
  id: PropTypes.string.isRequired,
  seen: PropTypes.bool,
  upcoming: PropTypes.bool,
  onClaim: PropTypes.func.isRequired,
}
