import * as ReactRouter from 'react-router-dom'
import * as ReactRedux from 'react-redux'
import * as React from 'react'
import querystring from 'querystring'

import * as Forms from '@rushplay/forms'
import * as Herz from '@rushplay/herz'

import * as Constants from './constants'
import * as Player from './player'
import * as Promotions from './promotions'
import * as Theming from './theming'
import { QueryDrawer } from './query-drawer'
import { Transaction, formName, makeDataSchema } from './transaction'
import { usePrev } from './use-prev'

export function Wallet() {
  const translate = Herz.I18n.useTranslate()
  const location = ReactRouter.useLocation()
  const dispatch = ReactRedux.useDispatch()
  const { authenticated } = Herz.Auth.useSession()

  const query = querystring.parse(location?.search?.substring(1))
  const prevQuery = usePrev(query)
  const [step, setStep] = React.useState(Constants.TransactionStep.Amount)
  const [firstLaunch, setFirstLaunch] = React.useState(true)

  // Fetch promotions on wallet open
  // TODO: remove when https://app.clickup.com/t/aprr3d is done
  React.useEffect(() => {
    if (prevQuery !== query && query.wallet) {
      dispatch([Promotions.fetch(), Player.fetchPlayerInfo()])
    }
  }, [query, prevQuery])

  /** Go out from opted campaigns when wallet closes
   *  or if app loaded for first time without opened wallet
   */
  React.useEffect(() => {
    if ((prevQuery.wallet || firstLaunch) && !query.wallet) {
      setStep(Constants.TransactionStep.Amount)
      if (firstLaunch) {
        setFirstLaunch(false)
      }
    }
  }, [query, prevQuery, firstLaunch])

  if (!authenticated || !query.wallet) {
    return null
  }

  return (
    <QueryDrawer
      activeQueryName="wallet"
      title={translate(`wallet.${query.wallet}`)}
    >
      <Theming.Alternative>
        <Transaction
          onStepChange={setStep}
          step={step}
          transactionType={query.wallet}
        />
      </Theming.Alternative>
    </QueryDrawer>
  )
}

const dataSchemaForKeysPreloading = makeDataSchema(
  { min: 1000, max: 5000 },
  6000
)
const preloadSchemaKeys = Forms.findTranslationKeys(
  formName,
  dataSchemaForKeysPreloading
)

Herz.I18n.Loader.preload(
  [
    'wallet.deposit',
    'wallet.withdraw',
    'wallet.withdrawal',
    ...preloadSchemaKeys,
  ],
  Wallet
)

// for @loadable/component
export default Wallet
