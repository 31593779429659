import * as React from 'react'
import PropTypes from 'prop-types'

import * as t from '@rushplay/theme'
import css from '@styled-system/css'
import styled from '@emotion/styled'
import { noneOf } from '@rushplay/common'

import { GameTileWidth } from './constants'

function tileWidth(props) {
  switch (props.size) {
    case 'large': {
      return `${GameTileWidth.LARGE}px`
    }
    case 'medium': {
      return `${GameTileWidth.MEDIUM}px`
    }
    default: {
      return `${GameTileWidth.SMALL}px`
    }
  }
}

const TileGrid = styled('div', {
  shouldForwardProp: noneOf(['layout', 'columns', 'size', 'containWidth']),
})`
  display: grid;
  grid-template-columns: auto;
  ${t.mediaQuery.sm`
  grid-template-columns: repeat(auto-fit, minmax(${tileWidth}, 1fr))
`};
  ${props =>
    props.columns > 1 &&
    props.size !== 'large' &&
    `
    grid-template-columns: repeat(2, 1fr);
  `};
  ${css({
    gridColumnGap: 1,
    gridRowGap: 1,
  })}
`

const SearchResultTileGrid = styled('div', {
  shouldForwardProp: noneOf(['layout', 'columns', 'size', 'containWidth']),
})`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  ${t.mediaQuery.md`
  grid-template-columns: repeat(auto-fit, ${tileWidth});
  `};
  ${css({
    gridColumnGap: 0,
    gridRowGap: 0,
  })}
`

export function GameTileGrid(props) {
  return props.columns <= 6 && props.size === 'small' && props.containWidth ? (
    <SearchResultTileGrid {...props} />
  ) : (
    <TileGrid {...props} />
  )
}

GameTileGrid.propTypes = {
  layout: PropTypes.oneOf(['grid', 'row']),
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  containWidth: PropTypes.bool,
  columns: PropTypes.number,
}
