import * as React from 'react'
import PropTypes from 'prop-types'
import { forceCheck } from 'react-lazyload'

import * as common from '@rushplay/common'

import * as icons from './icons'

export function GameTileLoading(props) {
  // The forceCheck is set on a timeout to allow Lazyload to
  // setup it's listeners properly before we trigger the event
  // Otherwise the event is never picked up and thus not updating the component..
  React.useEffect(() => {
    const id = setTimeout(() => {
      forceCheck()
    }, 10)

    return () => {
      clearTimeout(id)
    }
  }, [])

  return (
    <common.Box
      fontSize={8}
      display="flex"
      justifyContent="space-around"
      alignItems="center"
      flexDirection="column"
      height={props.height}
      border="1px dashed"
      borderColor="gameTileFeatured"
      color="rgba(255, 255, 255, 0.75)"
      borderRadius={1}
      backgroundColor="game-tile-background"
    >
      <icons.Spinner />
      <common.Box fontSize={1}>{props.title}</common.Box>
    </common.Box>
  )
}

GameTileLoading.propTypes = {
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  title: PropTypes.string,
}
