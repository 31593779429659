import * as React from 'react'
import PropTypes from 'prop-types'

import * as Common from '@rushplay/common'
import * as Herz from '@rushplay/herz'

export function EmptyPromotionInventoryCard(props) {
  const translate = Herz.I18n.useTranslate(
    () => [props.translationKey, props.titleTranslationKey],
    [props.translationKey, props.titleTranslationKey]
  )
  return (
    <Common.Box
      display="flex"
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="center"
      lineHeight="1.35"
      fontSize={1}
      textAlign="center"
      py={1}
      px={1}
      flexGrow="1"
      flexShrink="0"
      color="text"
    >
      <Common.Box pb={1} fontSize={6}>
        {props.icon}
      </Common.Box>
      <Common.Box fontSize={4} fontWeight={800} pt={0} pb={0}>
        {translate(props.titleTranslationKey)}
      </Common.Box>
      {translate(props.translationKey)}
    </Common.Box>
  )
}

EmptyPromotionInventoryCard.propTypes = {
  icon: PropTypes.node,
  titleTranslationKey: PropTypes.string,
  translationKey: PropTypes.string,
}

// for @loadable/components
export default EmptyPromotionInventoryCard
