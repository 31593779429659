import * as R from 'ramda'
import * as ReactRouter from 'react-router-dom'

import { getSearchQuery } from './get-search-query'

export function useGameInfoUrlQuery() {
  const location = ReactRouter.useLocation()
  const casinoReferrer =
    R.includes('referrer=casino', location.pathname) && 'casino'
  const liveCasinoReferrer =
    R.includes('referrer=live-casino', location.pathname) && 'live-casino'
  const searchQuery = getSearchQuery(location)
  const referrer =
    R.path(['referrer'], searchQuery) || casinoReferrer || liveCasinoReferrer
  const mode = R.path(['mode'], searchQuery)
  const mainReferrer = referrer && R.head(R.split('/', referrer))

  const paths = {
    dashboard: '/',
  }

  return {
    referrerPath: paths[mainReferrer] || `/${referrer}`,
    referrer: mainReferrer,
    mode,
  }
}
