import * as React from 'react'
import PropTypes from 'prop-types'

import * as Common from '@rushplay/common'
import * as Herz from '@rushplay/herz'
import styled from '@emotion/styled'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  align-items: center;
`

export function LoadingNotificationWrapper(props) {
  const [showNotification, setShowNotification] = React.useState(false)
  const translate = Herz.I18n.useTranslate(
    () => [
      `${props.name}.loading-notification`,
      'default.loading-notification',
    ],
    [props.name]
  )

  React.useEffect(() => {
    const timer = setTimeout(() => {
      if (props.shouldDisplay) {
        setShowNotification(true)
      }
    }, 1000)
    return () => {
      clearTimeout(timer)
      setShowNotification(false)
    }
  }, [props.shouldDisplay])

  if (!showNotification) {
    return props.children
  }

  return (
    <Wrapper>
      {props.children}
      {showNotification && translate('default.loading-notification') && (
        <Common.Text>
          {translate(`${props.name}.loading-notification`)
            ? translate(`${props.name}.loading-notification`)
            : translate('default.loading-notification')}
        </Common.Text>
      )}
    </Wrapper>
  )
}

LoadingNotificationWrapper.defaultProps = {
  shouldDisplay: false,
  name: 'default',
}

LoadingNotificationWrapper.propTypes = {
  children: PropTypes.node,
  shouldDisplay: PropTypes.bool,
  name: PropTypes.string,
}
