import * as React from 'react'

import * as Common from '@rushplay/common'
import * as Herz from '@rushplay/herz'
import styled from '@emotion/styled'

import * as Theming from '../theming'
import { Divider } from '../divider'
import { Heading } from '../heading'
import { useServerConfiguration } from '../server-configuration'

const Language = styled.span`
  &:hover,
  &:active {
    text-decoration: underline;
  }
`

export function LocationPicker() {
  const configuration = useServerConfiguration()
  const translate = Herz.I18n.useTranslate(
    () => [
      'change-location.title',
      ...configuration.countries.flatMap(country => [
        `l10n.region.${country?.alpha2?.toString().toLowerCase()}`,
        ...country.locales.map(
          locale =>
            `l10n.language.${locale?.language?.toString().toLowerCase()}`
        ),
      ]),
    ],
    [JSON.stringify(configuration.countries)]
  )

  return (
    <Theming.Alternative>
      <Common.Box
        bg="g-bg"
        color="page-nav-blue"
        px={[1, 2]}
        py={1}
        width="100%"
      >
        <Heading
          as="h1"
          level={2}
          color="page-nav-blue"
          data-testid="change-location.title"
        >
          {translate('change-location.title')}
        </Heading>
        <Common.Space py={[1, 2]}>
          <Divider />
        </Common.Space>
        <Common.Box
          display="grid"
          gridTemplateColumns={[
            'repeat(2, minmax(25%, 1fr))',
            'repeat(auto-fit, minmax(25%, 1fr))',
          ]}
          gridGap={[0, 1]}
          style={{ listStyle: 'none' }}
        >
          {configuration.countries.map(country => (
            <Common.Box as="li" key={country.alpha2} pr={0} py={0}>
              <Common.Box
                fontWeight="bold"
                data-testid={`change-location.country.${country.alpha2}`}
              >
                {translate(
                  `l10n.region.${country?.alpha2?.toString().toLowerCase()}`
                )}
              </Common.Box>
              <ul>
                {country.locales.map(locale => (
                  <Common.Box as="li" key={locale.code} fontSize={1} pt={0}>
                    <a
                      href={`/${locale.slug}/?confirm_locale=1`}
                      hrefLang={locale.slug}
                    >
                      <Language
                        data-testid={`change-location.language.${locale.language}`}
                      >
                        {translate(
                          `l10n.language.${locale?.language
                            ?.toString()
                            .toLowerCase()}`
                        )}
                      </Language>
                    </a>
                  </Common.Box>
                ))}
              </ul>
            </Common.Box>
          ))}
        </Common.Box>
      </Common.Box>
    </Theming.Alternative>
  )
}

// For @loadable/component
export default LocationPicker
