import * as React from 'react'
import * as ReactRedux from 'react-redux'

import * as Common from '@rushplay/common'
import * as Herz from '@rushplay/herz'
import css from '@styled-system/css'
import styled from '@emotion/styled'

import * as Configuration from './configuration'

const Image = styled.img`
  ${css({
    p: [1, null, 2],
    width: '100%',
    height: 'auto',
  })}
`
export function CurrencyProviderImages() {
  const paymentProviders = ReactRedux.useSelector(state =>
    Configuration.getPaymentProviders(state.configuration)
  )

  return (
    <Common.Box
      alignItems="center"
      display="flex"
      flexWrap="wrap"
      justifyContent="center"
      minHeight="74px"
      width="100%"
      borderTop="1px solid rgba(75, 186, 235, 0.20)"
      borderBottom="1px solid rgba(75, 186, 235, 0.20)"
      py={1}
    >
      {paymentProviders?.map(item => (
        <Common.Box
          key={item?.image?.src}
          height="24px"
          maxWidth="170px"
          alignItems="center"
          display="flex"
          justifyContent="center"
          my={0}
        >
          <Image src={item?.image?.url} alt="Visa" height="24" width="115" />
        </Common.Box>
      ))}
    </Common.Box>
  )
}

Herz.I18n.Loader.preload(
  [
    'landing-page.currencies.visa',
    'landing-page.currencies.trustly',
    'landing-page.currencies.sepa',
    'landing-page.currencies.muchbetter',
    'landing-page.currencies.skrill',
  ],
  CurrencyProviderImages
)
