import * as React from 'react'
import PropTypes from 'prop-types'
import { Route } from 'react-router-dom'

import * as Herz from '@rushplay/herz'

import { NotAuthenticated } from './not-authenticated'

export function PrivateRoute({ component: Component, ...props }) {
  const { authenticated } = Herz.Auth.useSession()

  return (
    <Route
      {...props}
      render={({ ...props }) => {
        if (authenticated) {
          return <Component {...props} />
        }

        return <NotAuthenticated />
      }}
    />
  )
}

PrivateRoute.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
    PropTypes.object,
  ]),
  path: PropTypes.string,
}
