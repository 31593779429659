import * as React from 'react'
import * as ReactRouter from 'react-router-dom'

export function useScrollToTop() {
  const ref = React.useRef()
  const { pathname } = ReactRouter.useLocation()
  const history = ReactRouter.useHistory()

  function handleScrollToTop() {
    // Has to run both scrollTo methods becuase of browser differences;
    // to ensure that it will scroll to top.
    ref.current && ref.current.scrollTo(0, 0)
    window.scrollTo(0, 0)
  }

  React.useEffect(() => {
    if (history.action !== 'POP') {
      handleScrollToTop()
    }
  }, [history, pathname])

  return [ref, handleScrollToTop]
}
