import * as React from 'react'
import PropTypes from 'prop-types'

import * as Common from '@rushplay/common'

export function SellingPoint(props) {
  return (
    <Common.Box
      maxWidth="275px"
      minWidth="150px"
      width="100%"
      minHeight="315px"
      py={20}
      px={16}
      borderRadius={8}
      background="white"
      display="flex"
      justifyContent="space-between"
      flexDirection="column"
      alignItems="center"
    >
      <Common.Box width="122px">
        <img width="100%" src={props.image} alt="selling-point"></img>
      </Common.Box>
      <Common.Box
        as="h3"
        style={{ textTransform: 'uppercase' }}
        fontWeight="700"
        color="#666975"
        py={0}
      >
        {props.title}
      </Common.Box>
      <Common.Box as="p" color="#666975" textAlign="center">
        {props.description}
      </Common.Box>
    </Common.Box>
  )
}

SellingPoint.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
}
