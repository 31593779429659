import * as ReactRedux from 'react-redux'
import * as React from 'react'
import { Helmet } from 'react-helmet-async'

import * as Configuration from './configuration'

export function XcmSeal() {
  const scriptId = ReactRedux.useSelector(state =>
    Configuration.getXcmSealScriptId(state.configuration)
  )

  React.useEffect(() => {
    if (scriptId) {
      window.XCM && window.XCM.init()
    }
  }, [scriptId])

  if (!scriptId) {
    return null
  }

  return (
    <React.Fragment>
      <Helmet>
        <script
          defer="defer"
          type="text/javascript"
          src={`https://${scriptId}.seals-xcm.certria.com/xcm-seal.js`}
        />
      </Helmet>
      <div
        id={`xcm-${scriptId}`}
        data-xcm-seal-id={scriptId}
        data-xcm-image-size="32"
        data-xcm-image-type="basic-small"
      />
    </React.Fragment>
  )
}
