import * as React from 'react'
import * as ReactRouter from 'react-router-dom'

import * as Herz from '@rushplay/herz'
import * as Common from '@rushplay/common'
import css from '@styled-system/css'
import styled from '@emotion/styled'

import * as Constants from '../constants'
import * as Cookies from '../cookies-module'
import { Button } from '../button'
import { LoginBox } from '../login-box'
import { useMenuQueries } from '../use-menu-queries'
import { useServerConfiguration } from '../server-configuration'
import { useTranslationImgproxyUrl } from '../use-imgproxy-url'

const HeaderText = styled.h3`
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Inter;
  font-size: 54px;
  font-style: normal;
  font-weight: 800;
  background: linear-gradient(
    180deg,
    rgba(43, 58, 102, 1) 48.21%,
    #101b3c 40.33%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-color: black;
  -webkit-text-stroke-width: 2px;
  line-height: 1.2;
`

const Logotype = styled('div')`
  ${css({
    width: '62px',
    height: '62px',
    backgroundImage: 'url(/images/logo-icon.svg)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    marginBottom: '16px',
  })};
`

export function LandingBanner() {
  const translate = Herz.I18n.useTranslate()
  const [returningPlayer] = Cookies.useCookie('returning_player')

  const { registerQuery } = useMenuQueries()

  const { country } = useServerConfiguration()

  const landingBanneImage = useTranslationImgproxyUrl(
    translate('landing-banner.image'),
    {
      resizingType: 'auto',
      resizingWidth: '1200',
      quality: '100',
    }
  )

  const landingBanneImageMobile = useTranslationImgproxyUrl(
    translate('landing-banner.image'),
    {
      resizingType: 'auto',
      resizingWidth: '650',
    }
  )

  const background = useTranslationImgproxyUrl(
    translate('landing-page.hero.background'),
    {
      resizingType: 'auto',
      resizingWidth: '1200',
    }
  )

  const backgroundMobile = useTranslationImgproxyUrl(
    translate('landing-page.hero.background'),
    {
      resizingType: 'auto',
      resizingWidth: '650',
    }
  )

  return (
    <Common.Box
      backgroundImage={[`url(${backgroundMobile})`, `url(${background})`]}
      backgroundRepeat="no-repeat"
      backgroundPosition="center"
      backgroundSize="cover"
      py={[null, 1, 2]}
      minHeight={['335px', '359px']}
      maxWidth={Constants.ContentMaxWidth}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      width="100%"
      borderRadius={[null, '30px']}
      position="relative"
      px={[0, null, 70]}
    >
      <Common.Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        width="100%"
        flexGrow="1"
        flexShrink="1"
        maxWidth="400px"
        backgroundColor="nav"
        borderRadius="16px"
        border="1px solid rgba(255, 255, 255, 0.30)"
        px={[1, 2]}
        py={[0, 1]}
        my={[1, null]}
        mx={[1, null]}
      >
        <Common.Box display="flex" flexDirection="column" alignItems="center">
          <Logotype />
          <Common.Box
            fontSize={5}
            fontWeight="bold"
            display="flex"
            justifyContent="space-around"
            fontFamily="proxima"
            color="page-nav-blue"
          >
            {translate('login-banner.welcome')}
            <Common.Box as="span" color="#60E0FD" ml="5px">
              {returningPlayer
                ? translate('login-banner.back')
                : translate('login-banner.offer')}
            </Common.Box>
          </Common.Box>
        </Common.Box>
        <Common.Box
          width="100%"
          height="160px"
          backgroundImage={[
            `url(${landingBanneImageMobile})`,
            `url(${landingBanneImage})`,
          ]}
          backgroundSize="cover"
          backgroundPosition="center"
          backgroundRepeat="no-repeat"
          borderRadius="10px"
          position="relative"
          my={[0, 1]}
        >
          {country.alpha2 === 'AU' ? (
            <Common.Box
              as="img"
              alt="AU"
              position="absolute"
              top="0%"
              left="0%"
              width={['150px', '216px']}
              height="auto"
              src={translate('landing-page.hero.flag')}
            ></Common.Box>
          ) : null}
        </Common.Box>
        {returningPlayer ? (
          <LoginBox labelFontColor="static-black" />
        ) : (
          <Common.Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            width="100%"
            mb={[0, 1]}
            mt={[1, '0px']}
          >
            <Common.Box
              mx="auto"
              mb={0}
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <Common.Box
                color="header-nav-blue"
                fontSize={'24px'}
                fontWeight={600}
                maxHeight="130px"
                maxWidth="450px"
                fontFamily="Inter"
                lineHeight="34px"
                as="h2"
              >
                {translate('landing-page.title')}
              </Common.Box>
              <Common.Box
                display="flex"
                flexDirection="row"
                justifyContent="center"
                alignItems="center"
              >
                <HeaderText>
                  {translate('landing-page.money-bonus.up-to')}
                </HeaderText>
              </Common.Box>
              <Common.Box
                color="#60E0FD"
                style={{ whiteSpace: 'pre-wrap' }}
                fontSize={'24px'}
                lineHeight="34px"
                fontWeight={600}
                fontStyle="normal"
              >
                {translate('landing-page.subheader')}
              </Common.Box>
            </Common.Box>
            <Common.Box
              display="flex"
              flexDirection={['column', 'row']}
              justifyContent="flex-start"
            >
              <Common.Box minWidth="100%" pt={0}>
                <ReactRouter.Link width="100%" to={`?${registerQuery}`}>
                  <Button
                    data-testid="landingpage.register-button"
                    stretch
                    variant="primary"
                    fontSize={3}
                    px="24px"
                    py="8px"
                  >
                    {translate('landing-page.get-started')}
                  </Button>
                </ReactRouter.Link>
              </Common.Box>
            </Common.Box>
          </Common.Box>
        )}
      </Common.Box>
    </Common.Box>
  )
}

Herz.I18n.Loader.preload(
  [
    'landing-banner.image',
    'landing-page.free-spins.game',
    'landing-page.get-started',
    'landing-page.hero.background-mobile',
    'landing-page.hero.background-mobile',
    'landing-page.hero.background',
    'landing-page.hero.background',
    'landing-page.hero.flag',
    'landing-page.money-bonus.up-to',
    'landing-page.subheader',
    'landing-page.title',
    'login-banner.back',
    'login-banner.offer',
    'login-banner.welcome',
    'main-menu.register',
  ],
  LandingBanner
)
