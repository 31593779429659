import * as React from 'react'
import PropTypes from 'prop-types'

import css from '@styled-system/css'
import styled from '@emotion/styled'

function useMousePosition(active, onChange) {
  React.useEffect(() => {
    function updatePosition(event) {
      const x = event.clientX
      const y = event.clientY

      onChange(x, y)
    }

    if (active) {
      window.addEventListener('mousemove', updatePosition)
      return () => {
        window.removeEventListener('mousemove', updatePosition)
      }
    }
  }, [active])
}

const Wrapper = styled.div`
  display: block;
  position: relative;
  ${props => props.disabled && 'pointer-events: none;'};
`

const Content = styled.div`
  position: relative;
  transform: perspective(50rem);
  transform-style: preserve-3d;
  transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
  width: 100%;
  overflow: hidden;
  ${css({
    borderRadius: 1,
    boxShadow: 1,
  })};
  ${props =>
    props.active &&
    `
    transform:
      perspective(50rem)
      rotateX(calc(var(--parallax-offset-y, 0) * -5deg))
      rotateY(calc(var(--parallax-offset-x, 0) * 5deg));
  `}
  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    background-image: radial-gradient(
      rgba(255, 255, 255, 0.15) 0%,
      rgba(255, 255, 255, 0) 75%
    );
    background-repeat: no-repeat;
    transition: opacity 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);

    ${props =>
      props.active &&
      `
      opacity: 1;
      transform:
        translateX(calc(var(--parallax-offset-x, 0) * -50%))
        translateY(calc(var(--parallax-offset-y, 0) * -50%))
        translateZ(0);
    `}
  }
`

export function Parallax(props) {
  const node = React.useRef()
  const [active, setActive] = React.useState(false)

  const onChange = React.useCallback((x, y) => {
    if (node.current == null) {
      return
    }

    const { top, left, width, height } = node.current.getBoundingClientRect()

    const centerX = left + width / 2
    const centerY = top + height / 2

    const offsetX = (x - centerX) / (width / 2)
    const offsetY = (y - centerY) / (height / 2)

    document.documentElement.style.setProperty('--parallax-offset-x', offsetX)
    document.documentElement.style.setProperty('--parallax-offset-y', offsetY)
  })

  useMousePosition(active, onChange)

  return (
    <Wrapper
      disabled={props.disabled}
      ref={node}
      onMouseEnter={() => setActive(true)}
      onMouseLeave={() => setActive(false)}
    >
      <Content active={active}>{props.children}</Content>
    </Wrapper>
  )
}

Parallax.propTypes = {
  children: PropTypes.node,
  disabled: PropTypes.bool,
}
