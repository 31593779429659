import * as React from 'react'
import * as ReactRouter from 'react-router-dom'

import * as Common from '@rushplay/common'
import * as Herz from '@rushplay/herz'

import * as http from './http'
import { Heading } from './heading'

export function NotAuthenticated() {
  http.useResponseStatus(401)
  const translate = Herz.I18n.useTranslate()

  return (
    <Common.Flex
      flexGrow="1"
      flexShrink="1"
      flexBasis="0"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <Heading level={1} color="g-text">
        401
      </Heading>
      <Common.Space p={2}>
        <Common.Text fontSize={3}>
          {translate('unauthorized.title')}
        </Common.Text>
      </Common.Space>

      <Common.Box
        fontSize={3}
        textAlign="center"
        style={{ textDecoration: 'underline' }}
      >
        <ReactRouter.Link to="/?login=me">
          {translate('unauthorized.link')}
        </ReactRouter.Link>
      </Common.Box>
    </Common.Flex>
  )
}

Herz.I18n.Loader.preload(
  ['unauthorized.title', 'unauthorized.link'],
  NotAuthenticated
)

// for @loadable/components
export default NotAuthenticated
