import * as R from 'ramda'
import { connect } from 'react-redux'

import * as player from '../player'

import { WithdrawalLimitInfo as Component } from './withdrawal-limit-info'

export const WithdrawalLimitInfo = R.compose(
  connect(
    state => {
      const withdrawalInfo = player.getWithdrawalInformation(state.player)
      const withdrawLimits = player.getWithdrawLimits(state.player)

      const dailyWithdrawLimit =
        withdrawLimits.find(limit => limit.interval === 'day') || {}

      return {
        bonusBalanceCents: withdrawalInfo.bonusBalanceCents,
        moneyBalanceCents: withdrawalInfo.moneyBalanceCents,
        wagerBalanceCents: withdrawalInfo.wagerBalanceCents,
        limitCents: dailyWithdrawLimit.limitCents,
        leftToWithdrawCents: dailyWithdrawLimit.leftToWithdrawCents,
      }
    },
    dispatch => ({
      onFetch: () => dispatch(player.fetchWithdrawInformation()),
    })
  )
)(Component)
