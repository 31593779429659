import * as R from 'ramda'
import * as ReactRedux from 'react-redux'
import * as EmotionTheming from 'emotion-theming'
import * as React from 'react'
import PropTypes from 'prop-types'
import URL from 'url'
import assert from 'assert'
import { border, flexbox, layout, position, space } from 'styled-system'

import styled from '@emotion/styled'

import * as ClientConfiguration from './client-configuration'
import * as Configuration from './configuration'
import { mapIndexed } from './map-indexed'

const Picture = styled.picture`
  display: contents;
`

const Image = styled.img`
  ${border};
  ${flexbox};
  ${layout};
  ${space};
  ${position};
`

const defaultParams = {
  cropWidth: 0,
  cropHeight: 0,
  dpr: 1,
  quality: 70,
  enlarge: false,
  gravity: 'ce',
  offsetX: 0,
  offsetY: 0,
  resizingType: 'fill',
  resizingHeight: 0,
  resizingWidth: 0,
}
const extensionFormats = ['jpg', 'png', 'webp']
const resizingTypes = ['fill', 'fit', 'auto']
const gravityTypes = [
  'no',
  'so',
  'ea',
  'we',
  'noea',
  'nowe',
  'soea',
  'sowe',
  'ce',
  'sm',
  'fp',
]

export function toImageUrl(
  imgproxyUrl = '',
  source = '',
  {
    cropHeight = defaultParams.cropHeight,
    cropWidth = defaultParams.cropWidth,
    dpr = defaultParams.dpr,
    quality = defaultParams.quality,
    enlarge = defaultParams.enlarge,
    extension = '',
    gravity = defaultParams.gravity,
    resizingHeight = defaultParams.resizingHeight,
    resizingType = defaultParams.resizingType,
    resizingWidth = defaultParams.resizingWidth,
    focusPointX,
    offsetX = defaultParams.offsetX,
    focusPointY,
    offsetY = defaultParams.offsetY,
  } = {}
) {
  const signature = 'insecure'

  if (process.env.NODE_ENV !== 'production') {
    assert(resizingTypes.includes(resizingType), `Incorrect "resizingType"`)

    assert(gravityTypes.includes(gravity), `Incorrect "gravity"`)

    if (gravity === 'fp') {
      assert(
        focusPointX && focusPointY,
        `"focusPointX" and "focusPointY" are required when "gravity" is "fp"`
      )
    }

    assert(
      [...extensionFormats, ''].includes(extension),
      `Incorrect "extension"`
    )
  }

  const processingOptions = {
    resize: [resizingType, resizingWidth, resizingHeight, enlarge ? 1 : 0],
    dpr: [dpr],
    quality: [quality],
    crop: [cropWidth, cropHeight],
    gravity:
      gravity === 'fp'
        ? ['fp', focusPointX, focusPointY]
        : [gravity, offsetX, offsetY],
  }

  const processingKeys = R.keys(processingOptions)

  const formattedProcessingOptions = R.reduce(
    (options, processKey) => {
      const option = processingOptions[processKey]

      if (R.not(R.any(R.isNil, option))) {
        return (options +=
          R.join(':', R.prepend(processKey, option)) +
          (R.indexOf(processKey, processingKeys) !==
          R.length(processingKeys) - 1
            ? '/'
            : ''))
      }

      return options
    },
    '',
    processingKeys
  )

  const url = `${imgproxyUrl}/${signature}/${formattedProcessingOptions}/plain${source}${
    R.length(extension) && R.not(R.includes(extension, source))
      ? `@${extension}`
      : ''
  }`

  return url
}

export function toTranslationImageUrl(imgproxyUrl, url, transformations) {
  const { pathname } = URL.parse(url)

  return toImageUrl(imgproxyUrl, pathname, transformations)
}

/**
 * Image component for resizing and converting remote images.
 * The component is using imgproxy API, see [imgproxy documenation]{@link https://github.com/imgproxy/imgproxy/blob/master/docs/generating_the_url_advanced.md} for more information
 * @param {Object} props
 */
export function DynamicImage(props) {
  const dpr = ClientConfiguration.useDevicePixelRatio()
  const imgproxyUrl = ReactRedux.useSelector(state =>
    Configuration.getImgproxyUrl(state.configuration)
  )
  const theme = EmotionTheming.useTheme()

  const options = {
    cropHeight: props.cropHeight,
    cropWidth: props.cropWidth,
    dpr: props.dpr || dpr,
    quality: props.quality,
    enlarge: props.enlarge,
    extension: props.extension,
    gravity: props.gravity,
    resizingHeight: props.resizingHeight,
    resizingType: props.resizingType,
    resizingWidth: Array.isArray(props.resizingWidth)
      ? props.resizingWidth[0]
      : props.resizingWidth,
    focusPointX: props.focusPointX,
    focusPointY: props.focusPointY,
    offsetX: props.offsetX,
    offsetY: props.offsetY,
  }

  const webpOptions = R.assoc('extension', 'webp', options)

  return (
    <Picture data-testid="dynamic-image">
      {Array.isArray(props.resizingWidth) &&
        mapIndexed(
          (width, index) => (
            <source
              key={`webp-${index}`}
              media={`(min-width: ${R.path(
                [index],
                R.reverse(
                  R.take(props.resizingWidth.length - 1, theme.breakpoints)
                )
              )})`}
              type="image/webp"
              srcSet={toImageUrl(
                props.imgproxyUrl || imgproxyUrl,
                props.source,
                R.assoc('resizingWidth', width, webpOptions)
              )}
            />
          ),
          R.reverse(R.drop(1, props.resizingWidth))
        )}
      <source
        type="image/webp"
        srcSet={toImageUrl(
          props.imgproxyUrl || imgproxyUrl,
          props.source,
          webpOptions
        )}
      />
      {Array.isArray(props.resizingWidth) &&
        mapIndexed(
          (width, index) => (
            <source
              key={index}
              media={`(min-width: ${R.path(
                [index],
                R.reverse(
                  R.take(props.resizingWidth.length - 1, theme.breakpoints)
                )
              )})`}
              srcSet={toImageUrl(
                props.imgproxyUrl || imgproxyUrl,
                props.source,
                R.assoc('resizingWidth', width, options)
              )}
            />
          ),
          R.reverse(R.drop(1, props.resizingWidth))
        )}
      <Image
        {...props}
        alt={props.alt}
        src={toImageUrl(
          props.imgproxyUrl || imgproxyUrl,
          props.source,
          options
        )}
      />
    </Picture>
  )
}

DynamicImage.propTypes = {
  alt: PropTypes.string,
  cropWidth: PropTypes.number,
  cropHeight: PropTypes.number,
  dpr: PropTypes.number,
  quality: PropTypes.number,
  enlarge: PropTypes.bool,
  extension: PropTypes.oneOf(extensionFormats),
  gravity: PropTypes.oneOf(gravityTypes),
  imgproxyUrl: PropTypes.string,
  resizingHeight: PropTypes.number,
  resizingType: PropTypes.oneOf(['fill', 'fit', 'auto']),
  resizingWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
  source: PropTypes.string.isRequired,
  focusPointX: PropTypes.number,
  focusPointY: PropTypes.number,
  offsetX: PropTypes.number,
  offsetY: PropTypes.number,
}

export function DynamicTranslationImage(props) {
  if (!props.source) {
    return null
  }
  const { pathname } = URL.parse(props.source)
  return <DynamicImage {...props} source={pathname} />
}

DynamicTranslationImage.propTypes = {
  source: PropTypes.string.isRequired,
}
