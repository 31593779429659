import * as React from 'react'
import * as PropTypes from 'prop-types'

import * as Common from '@rushplay/common'
import * as Forms from '@rushplay/forms'
import * as Herz from '@rushplay/herz'

import * as Icons from './icons'

const HINTS = {
  passwordLength: /.{8,}/,
  passwordContainsNumber: /\d/,
  passwordContainsCapitalLetter: /[A-Z]+/,
}

export function PasswordHints(props) {
  const translate = Herz.I18n.useTranslate()
  const field = Forms.useField(props.scope, { noRegister: true })

  return (
    <Common.Box mt="1">
      {Object.entries(HINTS).map(hint => (
        <Common.Box
          key={hint[0]}
          display="flex"
          alignItems="center"
          padding="4px"
        >
          <Common.Box
            color={
              field.value && hint[1].test(field.value) ? 'success' : 'input'
            }
            mx="4px"
            display="inline-flex"
          >
            <Icons.CheckCircle />
          </Common.Box>
          <Common.Text color="darken-gray" fontSize="1" lineHeight="1.5">
            {translate(`hint.${Herz.Utils.Strings.toKebabCase(hint[0])}`)}
          </Common.Text>
        </Common.Box>
      ))}
    </Common.Box>
  )
}

PasswordHints.propTypes = {
  scope: PropTypes.string.isRequired,
}

Herz.I18n.Loader.preload(
  [
    'hint.password-contains-number',
    'hint.password-length',
    'hint.password-contains-capital-letter',
  ],
  PasswordHints
)

export default PasswordHints
