import * as R from 'ramda'
import * as ReactRedux from 'react-redux'
import * as React from 'react'

import * as Common from '@rushplay/common'
import * as Herz from '@rushplay/herz'

import * as Inventory from './inventory'
import * as Icons from './icons'
import { Divider } from './divider'
import { EmptyPromotionInventoryCard } from './empty-promotion-inventory-card'
import { InventoryCard } from './inventory-card'

export function PromotionInventory() {
  const translate = Herz.I18n.useTranslate()

  const dateNow = Date.now()

  const inventoryItems = ReactRedux.useSelector(state =>
    Inventory.getActiveItems(state.inventory, { dateNow })
  )

  const futureInventoryItems = ReactRedux.useSelector(state =>
    Inventory.getFutureItems(state.inventory, { dateNow })
  )

  return (
    <React.Fragment>
      {R.and(R.isEmpty(inventoryItems), R.isEmpty(futureInventoryItems)) ? (
        <EmptyPromotionInventoryCard
          icon={<Icons.EmptyPromotionDrawer />}
          titleTranslationKey="promotion-inventory.no-items.title"
          translationKey="promotion-inventory.no-items"
        />
      ) : (
        <Common.Box display="grid" gridGap={0} pb={1}>
          {R.map(
            item => (
              <Common.Timestamp key={item.id}>
                {timestamp => <InventoryCard dateNow={timestamp} {...item} />}
              </Common.Timestamp>
            ),
            inventoryItems
          )}
        </Common.Box>
      )}
      {!R.isEmpty(futureInventoryItems) && (
        <Common.Box flexShrink="0" pb={1}>
          <Common.Space pt={1} pb={1}>
            <Divider />
          </Common.Space>
          <Common.Box pb={1} fontSize={3} fontWeight="bold" textAlign="center">
            {translate('promotion-inventory.upcoming')}
          </Common.Box>
          <Common.Box display="grid" gridGap={0}>
            {R.map(
              item => (
                <Common.Timestamp key={item.key}>
                  {timestamp => <InventoryCard dateNow={timestamp} {...item} />}
                </Common.Timestamp>
              ),
              R.sortBy(R.path(['activeFrom']), futureInventoryItems)
            )}
          </Common.Box>
        </Common.Box>
      )}
    </React.Fragment>
  )
}

Herz.I18n.Loader.preload(['promotion-inventory.upcoming'], PromotionInventory)
